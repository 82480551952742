import Flickity from 'flickity'

export default class Carousel {
  constructor(selector, options, onReady, onExit) {
    this.elements = document.querySelectorAll(selector)
    if (!this.elements.length) return

    this.instances = []

    this.elements.forEach((element, i) => {
      const container = element.querySelector('.carousel-container')
      const btnPrev = element.querySelector('.is-prev')
      const btnNext = element.querySelector('.is-next')
      const btnExit = element.querySelector('.is-exit')
      const opts = { ...options }

      if (element.classList.contains('has-captions')) {
        const captions = container.querySelectorAll('.caption')
        const ohCaption = element.querySelector('.oh-caption .wrapper')

        opts.on = Object.assign({}, options.on, {
          change: (index) => {
            ohCaption.innerHTML = captions[index].innerHTML.trim()

            container.focus({
              preventScroll: true,
            })
          },

          ready: () => {
            ohCaption.innerHTML = captions[0].innerHTML.trim()
          },
        })
      }

      requestAnimationFrame(() => {
        if (!btnExit) element.classList.remove('is-hidden')
        // console.log(this.options)
        this.instances[i] = new Flickity(container, opts)

        if (onReady) onReady()
      })

      if (btnPrev) {
        btnPrev.addEventListener('click', () => {
          this.instances[i].previous()
        })
      }

      if (btnNext)
        btnNext.addEventListener('click', () => {
          this.instances[i].next()
        })

      const nav = btnNext?.parentNode || btnPrev?.parentNode
      if (nav) {
        nav.addEventListener('mouseover', () => {
          this.instances[i]?.pausePlayer()
        })

        nav.addEventListener('mouseout', () => {
          this.instances[i]?.unpausePlayer()
        })
      }

      if (btnExit)
        btnExit.addEventListener('click', () => {
          if (onExit) onExit.call()
        })
    })
  }
}
