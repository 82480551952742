class CClickOutsideHandler {
  constructor() {
    this.isInit = false
  }

  init() {
    if (!this.isInit) {
      this.isInit = true

      document.addEventListener('click', (e) => {
        document
          .querySelectorAll('.dropdown.is-active, .has-dropdown.is-active')
          .forEach((dropdown) => {
            const target = e.target
            if (!dropdown.contains(target))
              dropdown.classList.remove('is-active')
          })
      })
    }
  }
}

export const ClickOutsideHandler = new CClickOutsideHandler()

export class CLazyHandler {
  constructor(element, fn, options) {
    const observer = new IntersectionObserver((entries) => {
      if (entries.some(({ isIntersecting }) => isIntersecting)) {
        observer.disconnect()
        fn()
      }
    }, options)

    observer.observe(element)
  }
}
