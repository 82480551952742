export default class Accordion {
  constructor(selector) {
    const blocks = document.querySelectorAll(
      selector ? selector : '.block-q_a_accordion'
    )
    if (!blocks) return
    this.onToggle = null

    document.addEventListener('click', (e) => {
      const trigger = e.target.closest('.js-accordion-trigger')

      if (trigger) {
        e.preventDefault()
        // e.stopPropagation()

        if (trigger.dataset.group) {
          const active = document.querySelector(
            '.js-accordion-trigger.is-active[data-group="' +
              trigger.dataset.group +
              '"]'
          )

          if (active) active.classList.remove('is-active')
        }

        trigger.classList.toggle('is-active')
        if (this.onToggle) this.onToggle.call()
      }
    })
  }
}
