import { CLazyHandler } from '../handlers'

export default class LazyLoadEmbed {
  constructor() {
    this.figures = document.querySelectorAll('.block-embed_block figure')

    this.figures.forEach((e) => {
      const template = e.querySelector('template')

      new CLazyHandler(e, () => {
        e.append(template.content)
        template.remove()
      })
    })
  }
}
